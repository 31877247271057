<template>
  <CRow>
    <CCol col="12" lg="12">
      <CCard>
        <CCardBody v-if="showPaymentLinkDetails">
          <div class="details-header row mx-2 mb-3">
            <h5>{{ $t("PAYMENT_LINKS.LABELS.REFERENCE") }}:</h5>
            <div class="details-header-title">{{ selected.order_ref_id }}</div>
          </div>
          <div class="table-responsive">
            <table class="table">
              <tbody>
                <tr>
                  <td class="bold">
                    {{ $t("PAYMENT_LINKS.LABELS.ORDER_ID") }}
                  </td>
                  <td>
                    <div @click="goTo('/orders/', selected.id)">
                      <p class="view-more">{{ selected.id }}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="bold">{{ $t("PAYMENT_LINKS.TABLE.AMOUNT") }}</td>
                  <td>{{ formatAmount(selected.amount) }}</td>
                </tr>
                <tr>
                  <td class="bold">
                    {{ $t("PAYMENT_LINKS.LABELS.CURRENCY") }}
                  </td>
                  <td>{{ selected.currency }}</td>
                </tr>
                <tr>
                  <td class="bold">{{ $t("PAYMENT_LINKS.TABLE.STATUS") }}</td>
                  <td>
                    <CBadge
                      :color="
                        getPaymentLinkBadge(getPaymentLinkStatus(selected))
                      "
                    >
                      {{ getPaymentLinkStatus(selected) }}
                    </CBadge>
                  </td>
                </tr>
                <tr>
                  <td class="bold">{{ $t("PAYMENT_LINKS.LABELS.URL") }}</td>
                  <td>
                    <input
                      disabled
                      class="col-6 px-2"
                      style="height: 33px"
                      v-bind:value="getPaymentLinkURL()"
                    />
                    <CButton
                      color="durianprimary"
                      @click="
                        copyToClipboard(
                          selected.payment_link_url,
                          'Payment link copied successfully'
                        )
                      "
                      class="ml-3"
                    >
                      {{ $t("COMMON.COPY_BUTTON") }}
                    </CButton>
                  </td>
                </tr>
                <tr>
                  <td class="bold">{{ $t("PAYMENT_LINKS.LABELS.CREATED") }}</td>
                  <td>{{ formatDate(selected.created_at) }}</td>
                </tr>
                <tr>
                  <td class="bold">{{ $t("PAYMENT_LINKS.LABELS.EXPIRY") }}</td>
                  <td>{{ formatDate(selected.expiry_date) }}</td>
                </tr>
                <tr v-if="hasPaymentId">
                  <td class="bold">
                    {{ $t("PAYMENT_LINKS.LABELS.PAYMENT_CHANNEL") }}
                  </td>
                  <td>{{ selected.payments.payment_channel }}</td>
                </tr>
                <tr>
                  <td class="bold">
                    {{ $t("PAYMENT_LINKS.LABELS.CUSTOMER_EMAIL") }}
                  </td>
                  <td>{{ selected.customer.email }}</td>
                </tr>
                <tr>
                  <td class="bold">
                    {{ $t("PAYMENT_LINKS.LABELS.CUSTOMER_DETAILS") }}
                  </td>
                  <td>
                    <div @click="goTo('/customers/' + selected.customer_id)">
                      <p class="view-more">{{ selected.customer_id }}</p>
                    </div>
                  </td>
                </tr>
                <tr v-if="hasPaymentId">
                  <td class="bold">
                    {{ $t("PAYMENT_LINKS.LABELS.PAYMENT_ID") }}
                  </td>
                  <td>
                    <div
                      @click="
                        hasPaymentId
                          ? goTo('/payments/', paymentId)
                          : null
                      "
                    >
                      <p class="view-more">{{ paymentId }}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="bold">
                    {{ $t("PAYMENT_LINKS.TABLE.NOTIFICATION") }}
                  </td>
                  <td>{{ selected.is_notification_enabled }}</td>
                </tr>
                <tr v-if="selected.description">
                  <td class="bold">
                    {{ $t("PAYMENT_LINKS.TABLE.NOTES") }}
                  </td>
                  <td>{{ selected.description }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="selected.items !== null">
            <ItemsTable v-bind:items="selected.items" />
          </div>
        </CCardBody>
        <CCardFooter>
          <CButton color="durianprimary" @click="goBack">
            {{ $t("COMMON.BACK_BUTTON") }}
          </CButton>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { getPaymentLink } from "@/api/payment_links.api.js";
import ItemsTable from "./ItemsTable.vue";
import { paymentLink } from "@/../config.js";
const PAYMENT_LINK_PREFIX = paymentLink.url.toString();
const COMPLETED = "completed";

export default {
  name: "PaymentLinkDetails",
  data() {
    return {
      selected: [],
      showPaymentLinkDetails: false,
      hasPaymentId: false,
      paymentId: null,
    };
  },
  components: {
    ItemsTable,
  },

  methods: {
    async getPaymentLinkDetailsById() {
      try {
        await getPaymentLink(
          this.$route.params.id,
          this.generateExpandParams([ "customer", "order", "items", "payments" ])
        ).then((response) => {
          this.selected = response.data.data;
          this.selected.payment_link_url =
            PAYMENT_LINK_PREFIX + this.selected.payment_link_url;
          this.showPaymentLinkDetails = true;
          this.checkPaymentId(this.selected.payments);
        });
      } catch (e) {
        if (e && e.response && e.response.data && e.response.data.error)
          this.showToaster(e.response.data.error);
      }
    },
    checkPaymentId(paymentDetails) {
      let paymentDetailsLength = paymentDetails.length;
      for (let i = 0; i < paymentDetailsLength; i++) {
        if (paymentDetails[i].status === COMPLETED) {
          this.paymentId = paymentDetails[i].id;
          this.hasPaymentId = true;
          break;
        }
      }
    },
    getPaymentLinkStatus(item) {
      if (item.status === COMPLETED) {
        return "paid";
      }
      let expiry = new Date(item.expiry_date);
      let currentDate = new Date();
      let dateDiff = currentDate - expiry;

      if (item.status === "processing" && dateDiff > 0) {
        return "expired";
      }
      return "unpaid";
    },
    getPaymentLinkURL() {
      return this.selected.payment_link_url;
    },
  },
  mounted() {
    this.getPaymentLinkDetailsById();
  },
};
</script>
