<template>
  <table class="table">
    <thead>
      <tr>
        <th>{{ $t("PAYMENT_LINKS.ITEMS_TABLE.ITEM_ID") }}</th>
        <th>{{ $t("PAYMENT_LINKS.ITEMS_TABLE.ITEM_NAME") }}</th>
        <th>{{ $t("PAYMENT_LINKS.ITEMS_TABLE.ITEM_QUANTITY") }}</th>
        <th>{{ $t("PAYMENT_LINKS.ITEMS_TABLE.ITEM_PRICE") }}</th>
        <th>{{ $t("PAYMENT_LINKS.ITEMS_TABLE.ITEM_TOTAL_PRICE") }}</th>
        <th>{{ $t("PAYMENT_LINKS.ITEMS_TABLE.LOGO_URL") }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in items" :key="item.id">
        <td>{{ item.id }}</td>
        <td>{{ item.name }}</td>
        <td>{{ item.qty }}</td>
        <td>{{ formatAmount(item.price) }}</td>
        <td>{{ getFormattedTotalAmount(item.price, item.qty) }}</td>
        <td>
          <img
            v-if="item.logo !== ''"
            height="20px"
            width="20px"
            alt="No Logo"
            :src="item.logo"
          />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
  export default {
    name: "ItemsTable",
    props: [ "items" ],
    methods: {
      getFormattedTotalAmount(amount, qty) {
        let formattedAmount = 0;
        formattedAmount = parseFloat(amount, 10) * qty;
        return this.formatAmount(formattedAmount.toString());
      }
    }
  };
</script>
